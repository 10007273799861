import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AuthState {
  _id: string | null;
  firstname: string | null;
  lastname: string | null;
  email: string | null;
  fullname: string | null;
  token: string | null;
}

const initialState: AuthState = {
  _id: null,
  firstname: null,
  lastname: null,
  email: null,
  fullname: null,
  token: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (
      state,
      action: PayloadAction<{
        _id: string;
        firstname: string;
        lastname: string;
        email: string;
        fullname: string;
        token: string;
      }>
    ) => {
      state._id = action.payload._id;
      state.firstname = action.payload.firstname;
      state.lastname = action.payload.lastname;
      state.email = action.payload.email;
      state.fullname = action.payload.fullname;
      state.token = action.payload.token;
    },
    auth_reset: (state) => {
      state._id = null;
      state.firstname = null;
      state.lastname = null;
      state.email = null;
      state.fullname = null;
      state.token = null;
    },
    defaultState: (state) => {
      state = initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUser, defaultState, auth_reset } = authSlice.actions;

export default authSlice.reducer;
