import * as mc from "../../shared/Muc-Imports";
import { useAppSelector } from "../../store/hook";
import {Link} from "react-router-dom";
import React from "react";

const useStyles = mc.makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "50px",
  },
  items: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Welcome = () => {
  const classes = useStyles();

  const data = useAppSelector((state) => state.auth);
  let Content;

  if (data.firstname !== null) {
    Content = (
<React.Fragment>
      <mc.Typography variant="h6" color="secondary">
        Welcome Back : {data.fullname}

        {/*<img src="http://drive.google.com/uc?export=view&id=171S508MJ4B1czniyR_DtqI0BQtGCZb3t" alt="drive image"/>*/}
      </mc.Typography>
  {/*<Link to={{ pathname: "https://google.com" }} target="_blank" />*/}

  <mc.Button style={{marginBottom:3}} variant="contained" href="http://drive.google.com/uc?export=view&id=13b0BJseWOZ7CccT7g_P-w2jqyYgqCy11" target="_blank">Windows 10 ISO</mc.Button>

  <mc.Button style={{marginBottom:3}} variant="contained" href="http://drive.google.com/uc?export=view&id=1WlyjzGdNw3o9ar9BTxJdDgCS9BbX1j5-" target="_blank">Windows 11 ISO</mc.Button>
  <mc.Button style={{marginBottom:3}} variant="contained" href="http://drive.google.com/uc?export=view&id=1bv6roNppP_tLT9L7WOt2hQAwnh1kwbEe" target="_blank">Windows 7 ISO</mc.Button>
  <mc.Button style={{marginBottom:3}} variant="contained" href="http://drive.google.com/uc?export=view&id=1qGPCugs35Vlds246ngNH-VF6YN6ZFWL9" target="_blank">WinPE 10 ISO</mc.Button>
</React.Fragment>

    );
  } else {
    Content = "";
  }
  return (
    <>{Content ? <mc.Box className={classes.root}>{Content}</mc.Box> : null}</>
  );
};

export default Welcome;
