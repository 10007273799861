import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    background: {
      // default: "#E5E4E2",
      // default: "#BFC9CA",
      default: "#f5f5f5",
      // default: "#F7F9F9",
    },
  },
  overrides: {
    // Style sheet name ⚛️
    // Write component name for style overrides
  },

  MuiCssBaseline: {
    "@global": {
      // write global styling here
    },
  },
  // set default props of any component
  props: {
    // Name of the component ⚛️
  },
});

export default theme;
