import * as mc from "../../shared/Muc-Imports";
import * as mi from "../../shared/Mui-Imports";
import React, { useState } from "react";
import { useLoginUserMutation } from "../../services/authApi";
import { useNavigate } from "react-router-dom";
import { setUser } from "../../store/authSlice";

import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../store/hook";

const useStyles = mc.makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "50px",
  },
  items: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Login = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.auth);
  if (user.token) {
    navigate("/");
  }
  const [loginUser, { data, isError, error, isSuccess }] =
    useLoginUserMutation();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    showPassword: false,
  });

  const { email, password, showPassword } = formData;

  const onSubmit = async (e: any) => {
    e.preventDefault();

    await loginUser({ email, password });
  };

  const handleClickShowPassword = () => {
    setFormData({
      ...formData,
      showPassword: !formData.showPassword,
    });
  };

  const handleMouseDownPassword = (event: any): void => {
    event.preventDefault();
  };

  const onChange = (e: any): void => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const signup = () => {
    navigate("/register");
  };

  if (isSuccess && data) {
    localStorage.setItem("token", data.token);
    const user = {
      _id: data._id,
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      fullname: data.firstname + " " + data.lastname,
      token: data.token,
    };

    dispatch(setUser(user as any));
    toast.success("Successfully logged in !");
    navigate("/");
  }
  if (isError && error) {
    const errMessage = (error as any).data?.message;
    if (errMessage === undefined) {
      console.log("Something went wrong...");
      toast.error("Something went wrong...");
    }
    toast.error(errMessage);
  }
  // useEffect(() => {
  //   if (isSuccess || user.token) {
  //     navigate("/");
  //   }
  //
  //   dispatch(auth_reset());
  // }, []);

  return (
    <>
      <mc.Box className={classes.root}>
        <mc.Grid
          style={{
            padding: "50px 5px 0 5px",
            width: 350,
            margin: "0 auto",
          }}
        >
          <mc.Grid container spacing={1}>
            <mc.Grid item xs={12} mb={0} className={classes.items}>
              <mi.HowToRegIcon
                color={"primary"}
                style={{ width: "70px", height: "70px" }}
              />
            </mc.Grid>
            <mc.Grid item xs={12} mb={2} className={classes.items}>
              <mc.Typography variant="h5" color="error">
                Login
              </mc.Typography>
            </mc.Grid>
          </mc.Grid>
          <form onSubmit={onSubmit}>
            <mc.Grid container spacing={1}>
              <mc.Grid xs={12} sm={12} mb={1} item>
                <mc.TextField
                  name="email"
                  type="email"
                  onChange={onChange}
                  label="Email"
                  value={email}
                  placeholder="Enter email"
                  fullWidth
                  required
                  variant={"outlined"}
                  InputProps={{
                    // classes: { input: classes.input1 },
                    endAdornment: (
                      <mc.InputAdornment position="end">
                        <mi.AlternateEmailIcon color={"success"} />
                      </mc.InputAdornment>
                    ),
                  }}
                />
              </mc.Grid>{" "}
              <mc.Grid xs={12} sm={12} mb={1} item>
                <mc.TextField
                  name="password"
                  type={showPassword ? "text" : "password"}
                  onChange={onChange}
                  label="Password"
                  value={password}
                  placeholder="Enter Password"
                  fullWidth
                  required
                  variant={"outlined"}
                  InputProps={{
                    // classes: { input: classes.input1 },
                    endAdornment: (
                      <mc.InputAdornment position="end">
                        <mc.IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <mi.VisibilityOff />
                          ) : (
                            <mi.Visibility color={"error"} />
                          )}
                        </mc.IconButton>
                      </mc.InputAdornment>
                    ),
                  }}
                />
              </mc.Grid>
            </mc.Grid>
            <mc.Grid container spacing={1} mt={2} mb={1}>
              <mc.Grid item xs={12}>
                <mc.Button
                  type="submit"
                  size={"large"}
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={<mi.LockOpenIcon />}
                  style={{ marginBottom: "15px" }}
                >
                  Login
                </mc.Button>
                <mc.Button
                  size={"large"}
                  variant="contained"
                  color={"success"}
                  fullWidth
                  onClick={signup}
                  startIcon={<mi.HowToRegIcon />}
                >
                  Signup
                </mc.Button>
              </mc.Grid>
            </mc.Grid>
          </form>
        </mc.Grid>
      </mc.Box>
    </>
  );
};

export default Login;
