import * as React from "react";
import * as mc from "../../shared/Muc-Imports";
import * as mi from "../../shared/Mui-Imports";
import { authApi } from "../../services/authApi";
import { contactsApi } from "../../services/contactApi";
import { useDispatch } from "react-redux";
import { auth_reset } from "../../store/authSlice";
import { useAppSelector } from "../../store/hook";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import UserIMG from '../../assets/img/login-user.png';

const pages = ["Products", "Pricing", "Blog", "Downloads", "Dashboard"];
// const settings = ["Profile", "Account", "Dashboard", "Logout"];

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const data = useAppSelector((state) => state.auth);

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logout = () => {
    localStorage.removeItem("token");
    dispatch(auth_reset());
    dispatch(authApi.util.resetApiState);
    dispatch(contactsApi.util.resetApiState);
    setAnchorElUser(null);
    toast.success("Successfully logged out");
    navigate("/login");
  };


  // @ts-ignore
  return (
    <mc.AppBar position="static" sx={{ bgcolor: "primary.dark" }}>
      <mc.Container maxWidth="xl">
        <mc.Toolbar disableGutters>
          <mc.Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
          ></mc.Typography>

          <mc.Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <mc.IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <mi.MenuIcon />
            </mc.IconButton>
            <mc.Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <mc.MenuItem key={page} onClick={handleCloseNavMenu}>
                  <mc.Typography textAlign="center">{page}</mc.Typography>
                </mc.MenuItem>
              ))}
            </mc.Menu>
          </mc.Box>
          <mc.Hidden smDown>
            <mi.AppsIcon
              style={{
                width: "42px",
                height: "42px",
                marginRight: "5px",
                marginLeft: "10px",
              }}
            />
          </mc.Hidden>

          <mc.Box sx={{ flexGrow: 1 }} style={{ marginLeft: 8 }}>
            <mc.Typography variant="h5"> Material UI</mc.Typography>
          </mc.Box>
          <mc.Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <mc.Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                {page}
              </mc.Button>
            ))}
          </mc.Box>
          {data.firstname && (
            <mc.Box sx={{ flexGrow: 0 }}>
              <mc.Tooltip title="Open settings">
                <mc.IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <mc.Avatar
                    alt={data.firstname}
                    // src="https://picsum.photos/400"
                    src={UserIMG}
                    // src="https://i.pravatar.cc/50"
                    // src="/static/images/avatar/2.jpg"
                  />
                </mc.IconButton>
              </mc.Tooltip>
              <mc.Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {/*{settings.map((setting) => (*/}
                {/*  <MenuItem key={setting} onClick={handleCloseUserMenu}>*/}
                {/*    <Typography textAlign="center">{setting}</Typography>*/}
                {/*  </MenuItem>*/}
                {/*))}*/}
                <mc.MenuItem onClick={handleCloseUserMenu}>
                  <mc.Typography textAlign="center">Home</mc.Typography>
                </mc.MenuItem>{" "}
                <mc.MenuItem onClick={() => logout()}>
                  <mc.Typography textAlign="center">Logout</mc.Typography>
                </mc.MenuItem>
              </mc.Menu>
            </mc.Box>
          )}
        </mc.Toolbar>
      </mc.Container>
    </mc.AppBar>
  );
};
export default Navbar;
