import "./App.css";
import { Route, Routes, Navigate, Outlet } from "react-router-dom";
import BaseTheme from "./shared/BaseTheme";
import { CssBaseline } from "@mui/material";
import Login from "./components/auth/Login";
import Welcome from "./components/welcome/Welcome";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "@mui/styles";
import { setUser } from "./store/authSlice";
import { useGetUserQuery } from "./services/authApi";
import React, { useEffect } from "react";
import { useAppDispatch } from "./store/hook";

const ProtectedRoute = ({ redirectPath = "/login" }) => {
  if (!localStorage.getItem("token")) {
    return <Navigate to={redirectPath} />;
  }

  return <Outlet />;
};

function App() {
  const dispatch = useAppDispatch();

  const { data, isSuccess, isError, error } = useGetUserQuery();
  useEffect(() => {
    if (localStorage.getItem("token")) {
      if (isSuccess && data) {
        const _token = localStorage.getItem("token");
        const user = {
          _id: data._id,
          firstname: data.firstname,
          lastname: data.lastname,
          email: data.email,
          fullname: data.firstname + " " + data.lastname,
          token: _token,
        };
        dispatch(setUser(user as any));
      }
      if (isError && error) {
        localStorage.removeItem("token");
      }
    }
  });

  return (
    <>
      <ThemeProvider theme={BaseTheme}>
        <CssBaseline />
        <ToastContainer position="top-right" theme="colored" autoClose={900} />

        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to="/" />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/" element={<Welcome />} />
          </Route>
        </Routes>
      </ThemeProvider>
    </>
  );
}

export default App;
